import * as React from "react"
import { Link } from "gatsby"
import resumeFile from '../../content/resume.pdf';

const Bio = () => {
  return (
    <div className="flex flex-col">
      <p className="text-xl mb-0">Hello! My name is <a href={resumeFile} title="Resume" target="_blank" rel="noopener noreferrer">Radoslav</a>. I now live in <a href="https://en.wikipedia.org/wiki/Sofia" title="Sofia" target="_blank" rel="noopener noreferrer">Sofia</a>, <a href="https://en.wikipedia.org/wiki/Bulgaria" title="Bulgaria" target="_blank" rel="noopener noreferrer">Bulgaria</a>.</p>

      <div className="text-xl flex flex-col">
        <h3>Me in 10 seconds</h3>

        <p className="leading-normal mb-3">
          <a href="https://github.com/rgenchev" title="GitHub Profile" target="_blank" rel="noopener noreferrer">Software engineer</a> who likes experimenting with new technologies.
        </p>

        <p className="leading-normal mb-3">
        Minimalist, introvert, and love having deep conversations.
        </p>

        <p className="leading-normal mb-3">
          Always in the mood to talk about tech, philosophy, psychology and history.
        </p>

        <p className="leading-normal">
          I like traveling and learning more about different cultures.
        </p>
      </div>

      <div className="text-xl">
        <h3>How I keep my mind fit?</h3>

        <ul className="list-disc list-inside">
          <li><a href="https://www.goodreads.com/user/show/18843930" title="Radoslav Genchev Goodreads Profile" target="_blank" rel="noopener noreferrer">reading</a></li>
          <li>journaling</li>
        </ul>
      </div>

      <div className="text-xl">
        <h3>How I keep my body fit?</h3>

        <ul className="list-disc list-inside">
          <li>brazilian jiu-jitsu</li>
          <li>calisthenics</li>
          <li>hiking</li>
          <li>walking</li>
        </ul>
      </div>

      <div className="text-xl">
        <h3>How I express my creativity?</h3>

        <ul className="list-disc list-inside">
          <li><Link to="/photos">photographing</Link></li>
        </ul>
      </div>
    </div>
  )
}

export default Bio
