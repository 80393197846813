import * as React from "react"

import Bio from "../components/bio"
import Layout from "../components/layout"
import Seo from "../components/seo"

const Index = () => {
  return (
    <Layout>
      <Seo title="" />

      <div className="mt-12">
        <Bio />
      </div>
    </Layout>
  )
}

export default Index
